import React, { Component, Fragment } from "react";
import { Link } from "gatsby";
import { Row, Col, Layout, Button, List, Icon, Card, Tag, BackTop } from "antd";
import {
  CheckOutlined,
  RocketOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

import ContactUs from "../../pages/contact";

const freelist = [
  "Online Calendar with Monthly, Weekly & Day Planner",
  "Free Reminders & Push Notifications",
  "Task Manager - with priority, checklists, reminders",
  "Fast Planner Basic",
  "Dashboard Overview",
  "Online Journal",
  "Notes System",
  "Basic Editor",
  "Boards System - Standard & Slides",
  "Contact Management",
  "Limited to 100 Entries Per Month",
  "Limited to 3 Months Historical Data Viewable",
];

const prolist = [
  "Everything In Free PLUS:",
  "Unlimited Entries",
  "All Historical Data Viewable",
  "Timeline Views",
  "Table Views",
  "Export to CSV",
  "Labels Management System",
  "Fast Planner Pro",
  "Pro Editor",
  "Boards System - Standard, Slides & Kanban",
  "Add Images & Files to entries",
  "Daily Todo List System",
  "Payments Tracker",
  "Recurring Entries",
  "Expanding Calendar",
  "Optional Integration With Google Calendar & Tasks",
];

const teamlist = [
  "Team Calendar with Monthly, Weekly & Day Planner",
  "Team Task Manager - with priority, checklists, reminders",
  "Team Boards System - Standard, Slides & Kanban",
  "Team Journal",
  "Team Dashboard",
  "Team Fast Planner ",
  "Team Contact Management",
  "Team Timeline View",
  "Team Table View",
  "Team Labels Management System",
  "Team Notes Organizer System",
  "Team Expenses & Payments Track",
  "Export to CSV",
  "Add Images & Files to entries",
  "Team Todo List System",
  "Recurring Entries",
  "Expanding Calendar",
  "Assign Tasks",
  "Task & Entry Comments",
  "Team Roles (Admin, Manager, Staff, Guest)",
  "Team Management Control (Owners / Admin)",
  "Optional Integration With Google Calendar & Tasks",
];

const teamlistForPopUp = [
  "Team Calendar with Monthly, Weekly & Day Planner",
  "Team Task Manager - with priority, checklists, reminders",
  "Team Boards System - Standard, Slides & Kanban",
  "Team Journal",
  "Team Dashboard",
  "Team Fast Planner ",
  "Team Contact Management",
  "Team Timeline View",
  "Team Table View",
  "Team Labels Management System",
  "Team Notes Organizer System",
  "Team Expenses & Payments Track",
  "Export to CSV",
  "Add Images & Files to entries",
  "Team Todo List System",
  "Recurring Entries",
  "Expanding Calendar",
  "Assign Tasks",
  "Task & Entry Comments",
  "Team Roles (Admin, Manager, Staff, Guest)",
  "Team Management Control (Owners / Admin)",
  "Optional Integration With Google Calendar & Tasks",
];

class FreePlanList extends Component {
  render() {
    return (
      <Fragment>
        <BackTop />
        <h2>
          <UserOutlined />
          Starter Plan
        </h2>
        <div className="plan-summary">
          <p>
            Free version of DayViewer for evaluation and trying out, or for low
            intensity use.
          </p>
        </div>

        <List
          style={{ margin: 5, paddingTop: 20 }}
          dataSource={freelist}
          renderItem={(item) => (
            <List.Item style={{ padding: "unset" }}>
              <div style={{ display: "flex" }}>
                {/* <div style={{ flex: 1 }}>
                  <CheckOutlined className="plan-list-icons" />
                </div> */}
                <div style={{ textAlign: "left" }}>{item}</div>
              </div>
            </List.Item>
          )}
        />
      </Fragment>
    );
  }
}

class ProPlanList extends Component {
  render() {
    return (
      <Fragment>
        <h2>
          <RocketOutlined />
          Pro Plan{" "}
          <Tag color="green">
            <CheckOutlined /> Most Popular
          </Tag>
        </h2>
        <div className="plan-subhead">
          <span>$8 / month [USD]</span> <br />
          <span>
            <del>$96</del> <strong>$69</strong> / year [USD] (yes 3 months FREE
            when paying annually!){" "}
          </span>
          <span className="plan-trial">7 days free trial</span>
        </div>
        <div className="plan-summary">
          <p>
            Pro DayViewer is the full version of DayViewer all features and
            views are unlocked as well as other enhancements.
          </p>
        </div>

        <List
          style={{ margin: 5, paddingTop: 20 }}
          dataSource={prolist}
          renderItem={(item) => (
            // <Row>
            //   <List.Item style={{ padding: "unset" }}>
            //     <Col xs={3}>
            //       <CheckOutlined className="plan-list-icons" />
            //     </Col>
            //     <Col xs={21}>
            //       <div style={{ textAlign: "left" }}>{item}</div>
            //     </Col>
            //   </List.Item>
            // </Row>
            <List.Item style={{ padding: "unset" }}>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1, paddingRight: 10 }}>
                  <CheckOutlined className="plan-list-icons" />
                </div>
                <div style={{ textAlign: "left" }}>{item}</div>
              </div>
            </List.Item>
          )}
        />
      </Fragment>
    );
  }
}

class TeamPlanList extends Component {
  render() {
    return (
      <Fragment>
        <h2>
          <TeamOutlined />
          Team Room Plan{" "}
          <Tag color="blue">
            <CheckOutlined /> Collaborate
          </Tag>
        </h2>
        <div className="plan-subhead">
          <span>
            $8 / member /month [USD] - up to 5 members <br />
            $6 / member /month [USD] - 6 - 20 members
            <br />
            $5 / member /month [USD] - 21+ members
          </span>
          <span className="plan-trial">14 days free trial</span>
          <i>
            Need a longer trial period or negotiate for a bigger team? get in
            touch{" "}
          </i>
          <span style={{ backgroundColor: "white", padding: 5 }}>
            <ContactUs />
          </span>
        </div>
        <div className="plan-summary">
          <p>
            Do everything in Pro DayViewer together with your team. Information
            Management and Planning around one central system.
          </p>
        </div>

        <List
          style={{ margin: 5, paddingTop: 20 }}
          dataSource={teamlist}
          renderItem={(item) => (
            <List.Item style={{ padding: "unset" }}>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1, paddingRight: 10 }}>
                  <CheckOutlined className="plan-list-icons" />
                </div>
                <div style={{ textAlign: "left" }}>{item}</div>
              </div>
            </List.Item>
          )}
        />
      </Fragment>
    );
  }
}

class TeamPlanListForPopUp extends Component {
  render() {
    return (
      <Fragment>
        <h2>
          <TeamOutlined />
          Team Room Plan{" "}
          <Tag color="blue">
            <CheckOutlined /> Collaborate
          </Tag>
        </h2>
        <div className="plan-subhead">
          <span>
            $8 / member /month [USD] - up to 5 members <br />
            $6 / member /month [USD] - 6 - 20 members
            <br />
            $5 / member /month [USD] - 21+ members
          </span>
          <span className="plan-trial">14 days free trial</span>
        </div>
        <div className="plan-summary">
          <p>Information and Tasks Planning around one central system.</p>
        </div>

        <List
          style={{ margin: 5, paddingTop: 20 }}
          dataSource={teamlistForPopUp}
          renderItem={(item) => (
            <List.Item style={{ padding: "unset" }}>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1, paddingRight: 10 }}>
                  <CheckOutlined className="plan-list-icons" />
                </div>
                <div style={{ textAlign: "left" }}>{item}</div>
              </div>
            </List.Item>
          )}
        />
      </Fragment>
    );
  }
}

export { FreePlanList, ProPlanList, TeamPlanList, TeamPlanListForPopUp };
